// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-library-tsx": () => import("./../../../src/pages/library.tsx" /* webpackChunkName: "component---src-pages-library-tsx" */),
  "component---src-pages-licensing-tsx": () => import("./../../../src/pages/licensing.tsx" /* webpackChunkName: "component---src-pages-licensing-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */)
}

